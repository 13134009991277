<template>
  <div id="app">
    <router-view class="main"/>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {}
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}

#app {
  background: #000;
}

.main {
  padding: 0 10px;
  margin: 0 auto;
  max-width: 1200px;
}
</style>
