import VueRouter from "vue-router";
import Vue from "vue";

const router = new VueRouter({
    mode: 'history',
    routes: [{
        name: 'index',
        path: '/',
        component: () => import('@/views/index')
    }]
})
Vue.use(VueRouter)
export default router